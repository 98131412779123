@import "constants";

#page-footer {
  position: relative;
  margin-top: 148px;
  background: $faded-background;
}

#footer-inner {
  display: flex;
  justify-content: space-between;
  max-width: $main-content-width;
  margin: 0 auto;
  padding: 76px 0;
}

#footer-logo-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  bottom: 60px;
}

#footer-left {
  display: flex;
  flex-direction: column;
  gap: 12px;

  a {
    text-decoration: none;
    color: white;
    font-family: $header-font;
    font-size: 24px;
    line-height: 30px;
    transition: $animation-time color;

    &:hover {
      color: $gold;
    }
  }
}

#footer-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: $mobile-screen-width) {
  #page-footer {
    margin-top: 60px;
  }

  #footer-inner {
    flex-direction: column;
    gap: 30px;
    padding: 30px 20px 50px;
  }

  #footer-logo-icon {
    display: none;
  }

  #footer-left {
    gap: 10px;

    a {
      font-size: 18px;
      line-height: 22px;
    }
  }

  #footer-right {
    .social-links {
      a {
        &:nth-child(1) {
          order: 2;
        }

        &:nth-child(2) {
          order: 3;
        }
      }

      .discord-btn {
        height: 40px;
        margin-right: 0;
      }
    }
  }
}
