#page-footer {
  background: #0e0e0e80;
  margin-top: 148px;
  position: relative;
}

#footer-inner {
  max-width: 1080px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 76px 0;
  display: flex;
}

#footer-logo-icon {
  padding: 16px;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}

#footer-left {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

#footer-left a {
  color: #fff;
  font-family: Righteous, sans-serif;
  font-size: 24px;
  line-height: 30px;
  text-decoration: none;
  transition: color .3s;
}

#footer-left a:hover {
  color: #9e7509;
}

#footer-right {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

@media (max-width: 1140px) {
  #page-footer {
    margin-top: 60px;
  }

  #footer-inner {
    flex-direction: column;
    gap: 30px;
    padding: 30px 20px 50px;
  }

  #footer-logo-icon {
    display: none;
  }

  #footer-left {
    gap: 10px;
  }

  #footer-left a {
    font-size: 18px;
    line-height: 22px;
  }

  #footer-right .social-links a:nth-child(1) {
    order: 2;
  }

  #footer-right .social-links a:nth-child(2) {
    order: 3;
  }

  #footer-right .social-links .discord-btn {
    height: 40px;
    margin-right: 0;
  }
}

/*# sourceMappingURL=index.1c5cbd72.css.map */
